<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Хаягийн жагсаалт</h3>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-select
              size="mini"
              class="mr10"
              placeholder="Төлөв"
              v-model="status"
              @change="selectStatus"
            >
              <el-option
                v-for="(status, index) in optionsStatus"
                :key="index"
                :label="status.label"
                :value="status.value"
              >
              </el-option>
            </el-select>
            <el-input
              style="width: 200px"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
              @change="getAddressList"
            />
          </div>
          <el-table :data="addressList">
            <el-table-column
              prop="city"
              label="Хот"
              width="100px"
            ></el-table-column>
            <el-table-column
              prop="district"
              label="Дүүрэг"
              width="100px"
            ></el-table-column>
            <el-table-column
              prop="horoo"
              label="Хороо"
              width="150px"
            ></el-table-column>
            <el-table-column prop="bairname" label="Байр"></el-table-column>
            <el-table-column
              prop="bairnote"
              label="Дэлгэрэнгүй"
            ></el-table-column>
            <el-table-column label="Төлөв" width="100px">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.is_active === 1">Идэвхтэй</el-tag>
                <el-tag v-else type="info">Идэвхгүй</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Үйлдэл" width="180px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  round
                  @click="handleClick(scope.row)"
                ></el-button>
                <el-button
                  type="info"
                  size="mini"
                  @click="getHistory(scope.row._id)"
                  >Түүх харах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="sizeChangeHandler"
            layout="total, sizes, prev, pager, next"
            :total="pageCount"
            :page-size="size"
            :page-sizes="[5, 10, 20, 50, 100]"
            @current-change="setPage"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <address-dialog
      :addressData="this.addressData"
      :type="false"
      :showAddressDialog="showAddressDialog"
      :closeFunction="closeDialog"
    ></address-dialog>
    <address-log-dialog-vue
      v-if="dialogLog === true"
      :logsData="logsData"
      :dialogBool="dialogLog"
      :logSizeChangeHandler="logSizeChangeHandler"
      :logSetPage="logSetPage"
      :logSize="logSize"
      :logPageCount="logPageCount"
      :close="close"
    />
  </div>
</template>

<script>
import AddressDialog from "./AddressDialog";
import AddressLogDialogVue from "./AddressLogDialog.vue";
import service from "@/helpers/services.js";
export default {
  name: "addressList",
  components: {
    AddressDialog,
    AddressLogDialogVue
  },
  mounted() {
    this.getAddressList();
  },

  data() {
    return {
      size: 20,
      pageCount: 0,
      from: 0,
      addressData: null,
      showAddressDialog: false,
      status: 1,
      search: "",
      addressList: [],
      optionsStatus: [
        {
          value: 1,
          label: "Идэвхтэй"
        },
        {
          value: 0,
          label: "Идэвхгүй"
        }
      ],
      logsData: [],
      logSize: 10,
      logPageCount: 0,
      logFrom: 0,
      logId: "",
      dialogLog: false
    };
  },

  methods: {
    selectStatus(status) {
      this.status = status;
      this.getAddressList();
    },
    setPage(page) {
      this.from = (page - 1) * this.size;
      this.getAddressList();
    },
    sizeChangeHandler(size) {
      this.size = size;
      this.getAddressList();
    },
    handleClick(data) {
      this.addressData = JSON.parse(JSON.stringify(data));
      this.showAddressDialog = true;
    },

    closeDialog() {
      this.addressData = null;
      this.showAddressDialog = false;
      this.getAddressList();
    },

    getAddressList() {
      this.openFullScreenLoader(true);
      service
        .getAllAddress(this.size, this.from, this.search, this.status, "")
        .then(response => {
          this.addressList = response.data.data.data;
          this.pageCount = response.data.data.total;
        });
      this.openFullScreenLoader(false);
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    getHistory(id) {
      this.logId = id;
      service
        .getOneLog(this.logSize, this.logFrom, this.logId)
        .then(logRes => {
          this.dialogLog = true;
          this.logsData = logRes.data.data.data;
          this.logPageCount = logRes.data.data.total;
        })
        .catch(er => {
          this.$notify({
            title: "Анхааруулга",
            message: "Алдаа түүх харах: " + er.message,
            type: "warning"
          });
        });
    },
    logSizeChangeHandler(size) {
      this.logSize = size;
      this.getHistory(this.logId);
    },
    logSetPage(page) {
      this.logFrom = (page - 1) * this.logSize;
      this.getHistory(this.logId);
    },
    close() {
      this.dialogLog = false;
    }
  }
};
</script>

<style></style>
